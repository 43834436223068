:global(.desktop) {

  .imageBlockSmall {
    flex: 0 10 614rem;
  }

  .imageBlockBig {
    flex: 0 10 750rem;
  }
}

:global(.mobile) {
  .label {
    bottom: 24rem;

    :global {

      .referralDigit {
        display: inline-block;
        width: 18rem;
        height: 18rem;
        text-align: center;
        background: theme('colors.gold.70');
        border-radius: 50%;
      }
    }
  }
}
