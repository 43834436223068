.description {

  p {
    display: inline;
  }

  p::after {
    white-space: pre;
    content: ' \A\A';
  }
}
