.root {
  --keyboard-focus-color: transparent;

  &.focused {

    .selectContainer {
      @apply border-black;
    }
  }

  &.errored {

    .selectContainer {
      @apply border-red;
    }

    .label,
    .select,
    .arrow {
      @apply text-red;
    }
  }
}

.label {
  position: absolute;
  left: 15rem;
  @apply text-gray-80;
  font-size: 16rem;
  transition: all 0.13s linear;
  pointer-events: none;

  .filled & {
    font-size: 10rem !important;
    line-height: 15rem !important;
  }
}

.select {
  width: 100%;
  @apply text-black;
  font-size: 16rem;
  line-height: 22rem;
  background-color: transparent !important;
  border: none;
  appearance: none;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 8rem;
  transform: translate(0, -50%);
  pointer-events: none;
}

.size-48 {

  .label {
    top: 5rem;
    line-height: 36rem;
  }

  .select {
    height: 48rem; /* required for Safari */
  }

  .selectWithLabel {
    padding: 19rem 48rem 7rem 16rem;
  }

  .selectNoLabel {
    padding: 13rem 48rem 13rem 16rem;
  }
}

.size-56 {

  .label {
    top: 7rem;
    line-height: 42rem;
  }

  .select {
    height: 56rem; /* required for Safari */
  }

  .selectWithLabel {
    padding: 21rem 48rem 13rem 16rem;
  }

  .selectNoLabel {
    padding: 17rem 48rem 17rem 16rem;
  }
}
