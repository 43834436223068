.root {
  &::after {
    position: absolute;
    right: 0;
    bottom: -1rem;
    left: 0;
    z-index: -1;
    background: theme('colors.black.DEFAULT');
    content: '';
  }
}

:global(.mobile) {
  .root {
    &::after {
      height: 80rem;
    }
  }
}

:global(.desktop) {
  .root {
    &::after {
      height: 121rem;
    }
  }
}
