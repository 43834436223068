.title {
  position: relative;
  z-index: 2;
  display: inline;

  &::after {
    position: absolute;
    z-index: -1;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.5;
    content: '';
    inset: -8rem;
  }
}

.logo {
  mix-blend-mode: multiply;
}

:global(.mobile) {
  .info {
    margin-top: -60rem;
    padding-top: 40rem;
  }

  .title {
    &::after {
      filter: blur(12rem);
    }
  }

  .logo {
    width: 272rem;
    margin-top: 24rem;
  }
}

:global(.desktop) {
  .info {
    margin-top: -24rem;
  }

  .title {
    &::after {
      filter: blur(24px);
    }
  }

  .logo {
    width: 280rem;
  }
}
