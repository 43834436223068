:global(.mobile) {
  .linkCard {
    flex: 0 1 100%;
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(4) {
      order: 2;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 4;
    }
    &:nth-child(6) {
      order: 5;
    }
    &:nth-child(5) {
      order: 6;
    }
  }
}

:global(.desktop) {
  .linkCard {
    &:nth-child(2n + 1) {
      flex: 0 1 calc(66.667% - 16rem);
    }

    &:nth-child(2n) {
      flex: 0 1 calc(33.333% - 16rem);
    }

    &:nth-child(3) {
      flex: 0 1 calc(33.333% - 16rem);
    }

    &:nth-child(4) {
      flex: 0 1 calc(66.667% - 16rem);
    }
  }
}
