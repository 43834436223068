.giftCard {
  position: relative;
  @apply bg-gold-30;
  border-radius: 6rem;
  cursor: pointer;

  &::after {
    display: block;
    width: 100%;
    padding-bottom: 59%;
    content: '';
  }

  &.active {
    cursor: default;
  }
}

.content {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 5rem;
  inset: 3px;
  @apply transition-all duration-200 ease-out;

  .active & {
    @apply border-white;
  }
}
