:global(.mobile) {

  .column2 {
    grid-column: 2 / span 2;
  }
}

:global(.desktop) {

  .grid {
    grid-template-columns: repeat(7, 120rem);
  }
}
