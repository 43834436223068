@keyframes openLayer {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes closeLayer {
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.layer {
  z-index: 5;
  overflow-y: auto;
  animation: openLayer 0.3s ease;
}

.close {
  animation: closeLayer 0.3s ease;
  pointer-events: none;
}
