.sticky {
  transition: opacity 200ms ease, transform 200ms ease;
}

.visible {
  transform: translateY(0);
  opacity: 1;
}

.hideTop {
  transform: translateY(-100%);
  opacity: 0;
}

.hideBottom {
  transform: translateY(100%);
  opacity: 0;
}


:global(.mobile) {

  .stickyBottom {
    padding-bottom: calc(16rem + env(safe-area-inset-bottom));
  }
}
