.textarea {
  --keyboard-focus-color: transparent;
  font-size: 16rem;
  line-height: 22rem;
  vertical-align: top;
  appearance: none;
  @apply placeholder-gray-80;

  &.standard {
    padding: 13rem 16rem;
  }

  &:focus {
    @apply border-black-100;
  }

  &.errored {
    @apply border-red;
    @apply border-red placeholder-red;
  }
}
