:global(.mobile) {

  .root {
    background: linear-gradient(180deg, #f3ebe8 57.8%, #b8957c 100%);
  }

  .image {
    height: 441rem;
  }
}

:global(.desktop) {

  .root {
    min-height: 544rem;
    background: linear-gradient(0deg, #eee5de, #eee5de);
  }

  .image {
    position: absolute;
    background-position: 100% 50%;
    inset: 0 50% 0 0;
  }

  .content {
    max-width: 480rem;
    margin: 0 auto;
  }
}


@media (min-width: 1280px) {
  :global(.desktop) {
    .content {
      margin-right: 0;
    }
  }
}
