.button {
  position: absolute;
  z-index: 10;
  vertical-align: top;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @apply mouse-focus:outline-none;

  &::before {

    /* make minimum clickable size */
    position: absolute;
    width: 36rem;
    height: 36rem;
    margin: auto;
    content: '';
    inset: -100%;
  }
}
