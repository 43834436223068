:global(.mobile) {

  .container {
    grid-template-columns: 1fr 152rem;
  }
}

:global(.desktop) {

  .container {
    grid-template-columns: 1fr 42.65%;
    max-width: 560rem;
    min-height: 312rem;
  }

  .imageContainer {
    height: 340rem;
    margin-top: -40rem;
  }
}
