.root {
  @apply select-none;

  &,
  & label {
    cursor: pointer;
  }
}

.disabled {
  &,
  & label {
    cursor: not-allowed;
  }
}
