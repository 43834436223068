.answerWrapper {
  flex-grow: 1;
}

:global(.mobile) {
  .root {
    width: 100%;
    height: 100%;
  }
}

:global(.desktop) {
  .root {
    height: 528rem;

    &::before {
      position: absolute;
      top: 0;
      left: calc(50% - 2rem);
      z-index: 1;
      width: 4rem;
      height: 100%;
      background: theme('colors.gold.30');
      content: '';
    }
  }

  .delimiter {
    position: absolute;
    top: calc(50% - 16rem);
    left: calc(50% - 16rem);
    z-index: 1;
    width: 32rem;
    height: 32rem;
    padding-top: 10rem;
    text-align: center;
    background: theme('colors.gold.30');
    border-radius: 50%;
  }
}
