.hint {
  @apply z-hint;
  display: none;

  &.visible {
    display: block;
  }
}

:global(.desktop) {
  .hint {
    max-width: 416rem;
  }
}
