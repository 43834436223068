.header {

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    @apply z-floating-header shadow bg-white;
  }
}

.letter {

  &.active {
    @apply text-gold-30;
  }

  &.disabled {
    @apply text-gray-50;
    pointer-events: none;
  }
}

.grid {
  display: inline-grid;
  grid-template-columns: repeat(27, 1fr);
  white-space: nowrap;
  column-gap: 8rem;
}

:global(.mobile) {

  .grid {
    grid-template-columns: repeat(27, 32rem);
    padding: 27rem 0;
  }
}

:global(.desktop) {

  .container {
    margin: 21rem 0;
  }

  .grid {
    grid-template-columns: repeat(27, 1fr);
    min-width: 100%;
    padding: 35rem 0;
  }
}
