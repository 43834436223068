.skipContent {
  position: absolute;
  top: auto;
  left: -999rem;
  z-index: -999;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus,
  &:active {
    top: auto;
    left: auto;
    z-index: 999;
    width: 30%;
    height: auto;
    margin: 10rem 35%;
    padding: 5rem;
    overflow: auto;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
    background-color: #000;
    border: 4rem solid yellow;
    border-radius: 15rem;
  }
}
