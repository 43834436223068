@keyframes openOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;

  &.animated {
    animation: openOverlay 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &.mounted {
    overflow: hidden auto;
  }
}
