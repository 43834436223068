.carousel {
  & > div {
    height: 100%;
  }

  :global {

    .glider,
    .glider-track,
    .glider-slide div,
    .glider-slide a {
      height: 100%;
    }
  }
}
