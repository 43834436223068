.mainContent {
  :global {
    .list-disc {
      list-style-type: disc;
    }

    .list-decimal {
      list-style-type: decimal;
    }
  }
}

:global(.mobile) {

  .mainContent {
    overflow-wrap: break-word;
  }
}

:global(.desktop) {

  .mainContent {
    max-width: 752rem;
  }
}
