 /* The !important flag is the only option here to override Iterate styles */

.custom-iterate-hq {
  html, body, #root {
    height: auto !important;
  }

 .iterate__container__border,
  .iterate__footer,
  .iterate__questionPrompt,
  .iterate__survey > :first-child,
  .iterate__survey p,
  .iterate__closeButton,
  input {
    display: none !important;
  }

  .iterate__survey > :nth-child(2) {
    margin: 0 !important;
  }

  .iterate__container {
    position: absolute !important;
    bottom: unset !important;
    left: 50% !important;
    z-index: -1 !important;
    background: transparent !important;
    box-shadow: none !important;
    transform: translateX(-50%) !important;
  }

  .iterate__survey {
    max-height: unset !important;
    padding: 0 !important;
    overflow: hidden !important;
  }

  .iterate__question > :last-child > div {
    display: flex !important;
    flex-wrap: wrap;
    gap: 12rem !important;
    justify-content: center;
    width: 100% !important;
  }

  .iterate__button {
    height: 58rem !important;
    margin: 0 !important;
    padding: 16rem !important;
    color: theme('colors.gold.70') !important;
    font-size: 20rem !important;
    line-height: 25rem !important;
    background-color: theme('colors.light-beige') !important;
    border-radius: 5rem !important;
    box-shadow: none !important;
  }
}

.custom-iterate-hq.mobile {

    .iterate__survey {
      width: 298rem !important;
      margin: 0 auto !important;
    }

    .iterate__question > :last-child > div {
      margin-bottom: 120rem !important;
    }

    .iterate__container {
      top: 150rem !important;
    }
  }

  .custom-iterate-hq.desktop {

    .iterate__survey {
      min-width: 921rem !important;
    }

    .iterate__container {
      top: 230rem !important;
    }

    .iterate__button {
      max-width: 298rem !important;
      cursor: pointer !important;
    }

    .iterate__button:hover {
      opacity: 0.8 !important;
    }
}
