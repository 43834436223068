:global(.mobile) {
  .header {
    position: relative;
  }

  .logo {
    justify-self: center;
  }

  .searchInput {
    margin-top: 48rem;
  }

  .closeButton {
    top: 0;
    right: 0;
  }
}

:global(.desktop) {
  .logo {
    margin-left: 12rem;
  }

  .closeButton {
    right: 48rem;
  }

  .header {
    grid-gap: 24rem;
    grid-template-columns: 144rem minmax(auto, 544rem) auto;
    align-items: center;
    justify-content: space-between;
  }
}
