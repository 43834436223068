/* top margin should be visible only when size between the image and viewport sides becomes 32px */
@media (min-width: 1505px) {

  .wideMT {
    margin-top: 32rem;
  }

  .wideMB {
    margin-bottom: 32rem;
  }
}
