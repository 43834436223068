.root {
  :global {
    .custom-story-group-container {
      cursor: pointer;
    }

    .custom-story-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 108rem;
    }

    .storyly__story-group-cover {
      box-sizing: content-box;
      width: 72rem;
      height: 72rem;
      padding: 3rem;
      border: 3rem solid theme('colors.gold.30');
      border-radius: 50%;
    }

    .storyly__story-group-seen .storyly__story-group-cover {
      padding: 6rem;
      border: none;
    }

    .storyly__story-group-title {
      margin-top: 8rem;
      font-size: 14rem;
      @apply font-sofia-pro;
      line-height: 20rem;
      text-align: center;
    }
  }
}
