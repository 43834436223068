.bar {
  height: 2px;
  border-radius: 2px;
}

.weak {
  @apply text-red;

  .bar {
    @apply bg-gray-30;

    &:first-child {
      @apply bg-red;
    }
  }
}

.medium {
  @apply text-orange;

  .bar {
    @apply bg-orange;

    &:last-child {
      @apply bg-gray-30;
    }
  }
}

.strong {
  @apply text-green;

  .bar {
    @apply bg-green;
  }
}
