.root {
  --keyboard-focus-color: transparent;

  &.focused {

    .input {
      @apply border-black;
    }
  }

  &.errored {

    .label {
      @apply text-red;
    }

    .input {
      @apply border-red;
    }
  }
}

.label-p2 {
  font-size: 16rem;
}

.label-p4 {
  font-size: 14rem;
}

.label {
  position: absolute;
  right: 0; /* to allow small overflow */
  left: 15rem;
  overflow: hidden;
  @apply text-gray-80;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.13s linear;
  pointer-events: none;

  .focused &,
  .filled & {
    font-size: 10rem !important;
    line-height: 15rem !important;
  }
}

.size-48 {

  .label {
    top: 5rem;
    line-height: 36rem;
  }

  .input {
    height: 48rem; /* required for Safari */
    padding: 19rem 16rem 7rem;
  }
}

.size-56 {

  .label {
    top: 7rem;
    line-height: 42rem;
  }

  .input {
    height: 56rem; /* required for Safari */
    padding: 21rem 16rem 13rem;
  }
}

.cross {
  top: 50%;
  right: 16rem;
  transform: translate(0, -50%);
}
