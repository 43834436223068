/* TODO replace with global styles when it's possible - added on 2021-04-12 by maddoger */
.breadcrumbs {
  font-size: 14rem;
  line-height: 20rem;
}

.link {
  @apply text-gold-70;

  @media (hover: hover) {
    &:not(.disabled) {
      &:hover,
      &:focus,
      &:active {
        @apply text-gold-30;
      }
    }
  }
}

.navItem {

  &::before {
    display: inline-block;
    height: 12rem;
    margin: 0 11rem -2rem;
    border-right: 1px solid currentColor;
    transform: rotate(28deg);
    content: '';
  }

  &:first-child {

    &::before {
      content: none;
    }
  }
}
