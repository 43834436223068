:global(.mobile) {

  .seconds {
    min-width: 100rem;
  }

  .milliseconds {
    min-width: 110rem;
  }
}

:global(.desktop) {

  .seconds {
    min-width: 135rem;
  }

  .milliseconds {
    min-width: 150rem;
  }
}
