:global(.desktop) {

  .activeButton {
    position: relative;
    z-index: 5;

    &::before,
    &::after {
      position: absolute;
      top: 40%;
      left: 0;
      z-index: -1;
      width: 96rem;
      height: 96rem;
      transform: rotate(65deg);
      transform-origin: 0 0;
      content: '';
    }

    &::after {
      left: 100%;
      transform: rotate(25deg);
    }
  }
}
