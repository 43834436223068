:global(.mobile) {

  .grid {
    grid-template-columns: 40rem 1fr 120rem;
  }

  .column2 {
    grid-column: 2 / -1;
  }
}


:global(.desktop) {

  .grid {
    grid-template-columns: 40rem 160rem 1fr;
  }

  .column2 {
    grid-column-start: 2;
  }
}
