.icon {
  position: relative;
  flex: none;
  cursor: pointer;
  @apply transition-all duration-200 ease-out bg-gray-10 border border-solid border-gray-30 rounded-full;

  &::before {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid;
    border-width: var(--size-5, 5rem);
    border-radius: 50%;
    content: '';
    inset: 0;
    @apply transition-all duration-200 ease-out;
  }

  &:hover,
  :global(.group):hover & {
    @apply border-gold-50;
  }

  &.active {
    @apply border-gold-30;

    &::before {
      background-color: #fff;
      @apply border-gold-30;
    }

    &:hover,
    :global(.group):hover & {

      &::before {
        @apply border-pink;
      }
    }
  }
}
