:global(.mobile) {

  .content {
    padding-bottom: 112rem;
  }

  .button + .button {
    margin-left: 62rem;
  }

  .introButton + .introButton {
    margin-left: 20rem;
  }
}

:global(.desktop) {

  .content {
    height: 528rem;
    padding-bottom: 40rem;
  }

  .button + .button {
    margin-left: 238rem;
  }

  .introContent {
    height: 528rem;
  }

  .introButton + .introButton {
    margin-left: 148rem;
  }
}
