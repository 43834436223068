.switch {
  @apply bg-gray-50;
  outline: none;
  cursor: pointer;

  &.active {
    @apply bg-gold-30;
  }
}

.handle {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  @apply transition-[left] duration-200 ease-out;
}

.size-30 {
  width: 52rem;
  height: 30rem;
  border-radius: 26rem;

  &.active {

    .handle {
      left: calc(100% - 27rem);
    }
  }

  .handle {
    top: 3px;
    left: 3px;
    width: 24rem;
    height: 24rem;
  }
}
