.tooltip {
  @apply z-tooltip;
  display: none;
  pointer-events: none;

  &.visible {
    display: block;
  }
}


:global(.desktop) {

  .tooltip {
    max-width: 416rem;
  }
}
