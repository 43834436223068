.root {

  :global(.recurly-element) {
    background: theme('colors.white');
    border: 1px solid theme('colors.gray.30');
    border-radius: 5rem;
  }

  &.focused {

    :global(.recurly-element) {
      @apply border-black;
    }
  }

  &.errored {

    .label {
      @apply text-red;
    }

    :global(.recurly-element) {
      @apply border-red;
    }
  }
}

.label {
  position: absolute;
  left: 15rem;
  @apply text-gray-80;
  font-size: 16rem;
  transition: all 0.13s linear;
  pointer-events: none;

  .focused &,
  .filled & {
    font-size: 10rem !important;
    line-height: 15rem !important;
  }
}

.size-48 {

  .label {
    top: 5rem;
    line-height: 36rem;
  }

  :global(.recurly-element) {
    height: 48rem;
  }
}

.size-56 {

  .label {
    top: 7rem;
    line-height: 42rem;
  }

  :global(.recurly-element) {
    height: 56rem;
  }
}
