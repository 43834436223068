.item {

  & + & {
    margin-top: 1px;

    &::before {
      position: absolute;
      top: -1px;
      right: 16rem;
      left: 16rem;
      height: 1px;
      @apply bg-gray-30;
      content: '';
    }
  }
}

.button {

  &:not(.disabled) {
    cursor: pointer;

    @media (hover: hover) {
      &.active,
      &:hover,
      &:focus,
      &:active {
        @apply text-gold-50;
      }
    }
  }
}
