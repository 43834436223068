@keyframes shine-lines {
  100% {
    transform: translateX(100%);
  }
}

.skeleton {
  mix-blend-mode: multiply;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.25), transparent);
    transform: translateX(-100%);
    animation: shine-lines 2s infinite;
    content: '';
  }
}
