.input {
  --keyboard-focus-color: transparent;
  padding: 0;
  font-size: 16rem;
  line-height: 22rem;
  background-color: transparent !important;
  border-bottom: 1px solid theme('colors.gold.30');
  appearance: none;
  @apply placeholder-gray-70;

  &::-webkit-search-cancel-button {
    display: none;
  }

  &.size-30 {
    height: 30rem; /* required for Safari */
    padding-bottom: 8rem;
  }

  &.size-34 {
    height: 34rem; /* required for Safari */
    padding-bottom: 12rem;
  }
}


:global(.mobile) {
  .input {
    text-align: left;
  }
}

:global(.desktop) {
  --search-indent: calc(50% - 69rem); /* 69rem is half the width of the label */

  .input {
    text-align: center;

    &:placeholder-shown {
      padding-left: var(--search-indent);
      text-align: left;
    }
  }
}
