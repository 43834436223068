:global(.mobile) {

  .root {
    min-height: 264rem;
  }

  .atomizer {
    right: 8rem;
    width: 212rem;
  }

  .productImage {
    width: 270rem;
  }

  .perfumeImage {
    left: 8rem;
    width: 244rem;
  }

  .label {
    width: 80rem;
    height: 80rem;
  }

  .caseLabel {
    top: 4rem;
    left: 84rem;
  }

  .fragranceLabel {
    bottom: 4rem;
    left: 28rem;
  }
}

:global(.desktop) {

  .root {
    min-height: 384rem;
  }

  .atomizer {
    width: 298rem;
    margin-right: 28rem;
  }

  .productImage {
    width: 320rem;
  }

  .perfumeImage {
    width: 344rem;
    margin-left: 28rem;
  }

  .label {
    width: 96rem;
    height: 96rem;
  }

  .caseLabel {
    top: 20rem;
    left: 112rem;
  }

  .fragranceLabel {
    top: 20rem;
    left: 64rem;
  }
}
