.withTail {

  &::after {
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-top: 2px solid theme('colors.gold.50');
    border-right: 3px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 3px solid transparent;
    content: '';
  }

  &.direction-left {

    &::after {
      left: 0;
      border-right-color: theme('colors.gold.50');
    }
  }

  &.direction-right {

    &::after {
      right: 0;
      border-left-color: theme('colors.gold.50');
    }
  }
}
