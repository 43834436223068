:global(.mobile) {

  .other\/long-arrow-right {
    @apply w-s40 h-s16;
  }

  .other\/apple-store,
  .other\/google-play {
    width: 168rem;
    height: 53rem;
  }

  .other\/amazon {
    width: 132rem;
    height: 40rem;
  }

  .other\/paypal {
    width: 95rem;
    height: 26rem;
  }

  .other\/scentbird-logo-medium {
    width: 144rem;
    height: 32rem;
  }

  .other\/scentbird-logo-small {
    width: 134rem;
    height: 32rem;
  }

  .other\/scentbird-monogram {
    width: 77rem;
    height: 71rem;
  }

  .other\/no-items {
    width: 146rem;
    height: 168rem;
  }
}

:global(.desktop) {
  .other\/long-arrow-right {
    width: 41rem;
    height: 16rem;
  }
}
