.button {

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    opacity: 0.01;
  }
}
