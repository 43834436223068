.size-1 {
  flex: 0 1 calc(100% / 3 - 16rem);
}

.size-2 {
  flex: 0 1 calc(100% / 3 * 2 - 16rem);
}

.size-3 {
  flex: 0 1 100%;
}

.children-full-width > div {
  width: 100%;
}
