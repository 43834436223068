.notifications {
  position: fixed;
  top: 16rem;
  right: 16rem;
  transition: transform 0.2s linear;
  @apply z-tooltip;
}


:global(.mobile) {

  .notifications {
    left: 16rem;
  }
}

:global(.desktop) {

  :global(.sidebar-right-open) .notifications {
    right:  496rem; /* 464rem + 32rem */
  }
}
