.clickable {

  @media (hover: hover) {
    &:hover {

      .card {
        @apply border-gold-50;
      }
    }
  }
}

.activeCard {
  @apply border-gold-70;
  box-shadow: inset 0 0 0 2px theme('colors.gold.70');
}
