@keyframes show {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: none;
  }
}
:global(.desktop) {

  .active {
    height: 2px;
    animation: show 0.3s ease;
  }
}
