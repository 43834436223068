.root {
  transform: translate3d(0, var(--hide-y), 0);
  transition: transform 0.35s ease;
}

.top {
  --hide-y: -100%;
}

.bottom {
  --hide-y: 100%;
}

.visible {
  transform: translate3d(0, 0, 0);
}

:global(.mobile) {

  .bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
