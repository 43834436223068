@keyframes openNotification {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.notification {
  &.closed {
    @apply transition-all duration-300 ease-out z-1 opacity-0;
  }

  &:not(:first-child) {
    margin-top: 16rem;
  }
}

.notificationWithAnimation {
  animation: openNotification 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

:global(.body-scroll-frozen) {

  .notificationUnderPopups {
    display: none;
  }
}

:global(.mobile) {

  .notification {
    width: 100%;
  }
}

:global(.desktop) {

  .notification {
    width: 384rem;
  }
}
