.button {
  @apply transition-all duration-200 ease-out;
  @apply select-none;

  &:not(.disabled) {

    &:hover,
    &:focus {
      @apply duration-0;
    }
  }
}

.withBorder {
  border-top: 1px solid theme('colors.gray.20');
}

.withBorderBottom {
  border-bottom: 1px solid theme('colors.gray.20');
}

.style-gold-50 {
  @apply text-gold-50;

  &.disabled {
    @apply text-gray-50;
  }

  @media (hover: hover) {
    &:not(.disabled) {
      &:hover,
      &:focus,
      &:active {
        @apply text-gold-30;
      }
    }
  }
}

.style-black {
  @apply text-black;

  &.disabled {
    @apply text-gray-50;
  }

  @media (hover: hover) {
    &:not(.disabled) {
      &:hover,
      &:focus,
      &:active {
        @apply text-gray-80;
      }
    }
  }
}
