/* ATTN class names should be taken from https://tailwindcss.com/docs */

/* Most popular grid views */
.grid-cols-2-center {
  grid-template-columns: repeat(4, 1fr);

  & > * {
    grid-column: span 2;

    /* Dealing with single orphan */

    &:last-child:nth-child(2n - 1) {
      grid-column-end: -2;
    }
  }
}

.grid-cols-3-center {
  grid-template-columns: repeat(6, 1fr);

  & > * {
    grid-column: span 2;

    /* Dealing with 2 orphan items */

    &:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
    }

    /* Dealing with single orphan */

    &:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }
}

.grid-cols-4-center {
  grid-template-columns: repeat(8, 1fr);

  & > * {
    grid-column: span 2;

    /* Dealing with 3 orphan items */

    &:last-child:nth-child(4n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(4n - 2) {
      grid-column-end: -4;
    }

    &:nth-last-child(3):nth-child(4n - 3) {
      grid-column-end: 4;
    }

    /* Dealing with 2 orphan items */

    &:last-child:nth-child(4n - 2) {
      grid-column-end: -3;
    }

    &:nth-last-child(2):nth-child(4n - 3) {
      grid-column-end: 5;
    }

    /* Dealing with single orphan */

    &:last-child:nth-child(4n - 3) {
      grid-column-end: 6;
    }
  }
}

.grid-cols-6-center {
  grid-template-columns: repeat(12, 1fr);

  & > * {
    grid-column: span 2;

    /* Dealing with 5 orphan items */

    &:last-child:nth-child(6n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(6n - 2) {
      grid-column-end: -4;
    }

    &:nth-last-child(3):nth-child(6n - 3) {
      grid-column-end: -6;
    }

    &:nth-last-child(4):nth-child(6n - 4) {
      grid-column-end: 6;
    }

    &:nth-last-child(5):nth-child(6n - 5) {
      grid-column-end: 4;
    }

    /* Dealing with 4 orphan items */

    &:last-child:nth-child(6n - 2) {
      grid-column-end: -3;
    }

    &:nth-last-child(2):nth-child(6n - 3) {
      grid-column-end: -5;
    }

    &:nth-last-child(3):nth-child(6n - 4) {
      grid-column-end: -7;
    }

    &:nth-last-child(4):nth-child(6n - 5) {
      grid-column-end: 5;
    }

    /* Dealing with 3 orphan items */

    &:last-child:nth-child(6n - 3) {
      grid-column-end: -4;
    }

    &:nth-last-child(2):nth-child(6n - 4) {
      grid-column-end: -6;
    }

    &:nth-last-child(3):nth-child(6n - 5) {
      grid-column-end: 6;
    }

    /* Dealing with 2 orphan items */

    &:last-child:nth-child(6n - 4) {
      grid-column-end: -5;
    }

    &:nth-last-child(2):nth-child(6n - 5) {
      grid-column-end: 7;
    }

    /* Dealing with single orphan */

    &:last-child:nth-child(6n - 5) {
      grid-column-end: 8;
    }
  }
}

/* Display */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

/* Border color */
.border-solid-white {
  @apply border border-solid border-white;
}

.border-solid-gray-30 {
  @apply border border-solid border-gray-30;
}

.border-solid-gray-50 {
  @apply border border-solid border-gray-50;
}

.border-solid-gray-80 {
  @apply border border-solid border-gray-80;
}

.border-solid-gold-50 {
  @apply border border-solid border-gold-50;
}

.border-solid-gold-30 {
  @apply border border-solid border-gold-30;
}

.border-solid-pink {
  @apply border border-solid border-pink;
}

/* Custom ============================================================ */
.shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 6rem;
}

.hide-empty:empty {
  display: none;
}


/* Hide Scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Copy */
.link-black {
  @apply text-black desktop-hover:text-gray-90;
}

.link-gold-50 {
  @apply text-gold-50 desktop-hover:text-gold-30;
}

.link-gray-30 {
  @apply text-gray-30 desktop-hover:text-gray-20;
}

.link-gray-40 {
  @apply text-gray-40 desktop-hover:text-gray-30;
}

/* texts */
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6,
.text-h7,
.text-h8,
.text-h9 {
  @apply font-tt-norms-pro font-bold uppercase;
}

/* Secondary Headers */
.text-sh1,
.text-sh2,
.text-sh3,
.text-sh4,
.text-sh5,
.text-sh6 {
  @apply font-medium;
}

/* Eyebrow */
.text-eye1,
.text-eye3 {
  @apply font-tt-norms-pro font-bold uppercase;
}

.text-eye2 {
  @apply font-tt-norms-pro font-medium uppercase;
}

.text-t1,
.text-t2 {
  @apply font-semibold;
}

.text-t3 {
  @apply font-normal;
}

.text-t4,
.text-t6 {
  @apply font-medium uppercase;
}

.text-t5 {
  @apply font-tt-norms-pro font-bold uppercase;
}

.text-t7 {
  @apply font-tt-norms-pro font-medium uppercase;
}
