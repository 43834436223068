.pagination {
  display: inline-flex;
  align-items: center;
  font-size: 14rem;
  line-height: 24rem;
  list-style: none;
  user-select: none;
}

.page {
  vertical-align: top;

  &:first-child {
    margin-left: 0;
  }

  /* .page.disabled can be only on prev and next nodes (arrows) */

  &:global(.disabled) {
    display: none !important;
  }

  &.active {

    a {
      @apply text-white bg-black;
      cursor: default;
    }

    .pageLink {
      min-width: 24rem;
      height: 24rem;
      padding: 0 8rem;
      border-radius: 12rem;
    }
  }
}

.pageLink {
  display: block;
  font-weight: 700;
  @apply font-tt-norms-pro;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.arrowLink {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 12rem;
  cursor: pointer;
}


:global(.mobile) {

  .page {
    margin-left: 12rem;
  }
}

:global(.desktop) {

  .page {
    margin-left: 24rem;
  }
}
