.title {
  display: inline;

  &::after {
    position: absolute;
    z-index: -1;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.5;
    content: '';
    inset: -8rem;
  }
}

.title,
.established {
  position: relative;
  z-index: 2;
}

:global(.mobile) {

  .title {
    display: block;

    &::after {
      right: 0;
      filter: blur(12rem);
    }
  }
}

:global(.desktop) {

  .title {

    &::after {
      filter: blur(24px);
    }
  }
}
