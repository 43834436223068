.bone {
  --fpo-size-compensation: calc(var(--fpo-indent, 0rem) + var(--fpo-border, 0px));
  --fpo-shift-compensation: calc(0rem - var(--fpo-indent, 0rem) - var(--fpo-border, 0px));
  height: var(--fpo-size);

  &::before {
    position: absolute;
    top: var(--fpo-shift-compensation);
    left: var(--fpo-shift-compensation);
    display: block;
    width: calc(100% + var(--fpo-size-compensation) * 2);
    height: calc(var(--fpo-size) + var(--fpo-size-compensation));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: inherit;
    content: '';
  }
}

:global(.mobile) {
  .bone::before {
    background-image: url('./images/mobile/fpo.jpg');
  }
}

:global(.desktop) {
  .bone::before {
    background-image: url('./images/desktop/fpo.jpg');
  }
}
