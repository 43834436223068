:global {
  #parcellab-track-and-trace-ui-wrapper {
    @apply font-medium w-full;

    img {
      max-width: 100%;
    }

    .pl-col-row {
      @apply flex flex-row gap-16;

      > * {
        @apply flex-1;
      }

      section, div:has(> .pl-vote) {
        @apply flex flex-col gap-y-16 h-full;
      }

      .pl-vote {
        @apply px-32 py-24;

        .pl-vote-label {
          @apply text-center;

          b {
            @apply font-bold;
          }
        }

        .pl-rating-body {
          @apply flex justify-center mt-16;

          .pl-courier-vote {
            @apply cursor-pointer flex-1 grid place-items-center py-4;

            &:first-child {
              @apply !border-r-transparent;
            }
          }
        }
      }
    }

    .pl-box-aside-left {
      @apply max-w-[368rem] w-full;
    }

    .pl-action-box, .pl-box-body, .pl-vote, .pl-courier-vote {
      @apply border-[1rem] !border-gray-30 !rounded-none;
    }

    .pl-box-body {
      @apply flex-1;
    }

    #pl-courier-fwd-link, #pl-reroute-link, .pl-button {
      @apply !rounded-none !text-black;
    }

    #pl-courier-fwd-link, #pl-reroute-link {
      @apply !bg-gold-30 text-center min-h-[100rem];
    }

    #pl-reroute-link {
      @apply p-16 mt-auto;

      .pl-short-info {
        @apply text-t5;
      }

      img {
        @apply mb-6;
      }
    }

    #pl-courier-fwd-link {
      @apply block p-32;

      .pl-further-infos-sub {
        @apply text-t5;
      }
    }

    .pl-action-box {
      @apply p-32 text-center flex flex-col gap-y-24;

      .pl-box-heading, .pl-cal-week-day {
        @apply text-sh3;
      }

      .pl-action-box-content img {
        @apply mb-24;
      }

      .pl-calendar {
        @apply flex flex-col gap-y-6;

        .pl-cal-week-day {
          @apply text-gold-50 mb-8;
        }

        .pl-cal-day {
          @apply font-bold leading-[64rem] text-[64rem] font-tt-norms-pro;
        }

        .pl-cal-month {
          @apply text-sh5;
        }
      }

      &:not(.pl-time-box-data) {
        @apply py-[70rem];
      }
    }

    .pl-time-box-data {
      @apply flex flex-row gap-x-40 items-center text-h5;
    }

    .pl-time-box-caption {
      @apply text-t5 text-gray-40;
    }

    .pl-checkpoints-container {
      @apply px-32 py-24 h-full;

      &, ul {
        @apply flex flex-col gap-y-24;
      }

      ul {
        @apply text-t4 flex-1;
      }

      .pl-icon-hr-container {
        @apply flex justify-between relative max-w-464 mx-auto w-full;

        .pl-icon-hr {
          @apply absolute top-1/2 -translate-y-1/2 h-0 w-full border-dashed border-t-[1rem] border-gray-20 -z-1;
        }

        .pl-hr-icon {
          @apply bg-white;
        }

        .pl-state-icon-inactive {
          @apply opacity-30 scale-90;
        }

        .pl-state-icon-active {
          @apply scale-125;
        }
      }

      .pl-checkpoint-time {
        @apply text-gray-40 font-normal;
      }

      .pl-checkpoint b {
        @apply font-bold;
      }

      #pl-show-more-button {
        @apply text-t5 !text-gold-30 uppercase;
      }
    }

    @media only screen and (max-width: 568px) {
      .pl-box-aside-left {
        @apply max-w-full;
      }

      .pl-col-row {
        @apply flex flex-col;
      }
    }
  }
}
