.rating {
  display: inline-grid;
  grid-template-columns: repeat(5, 16.66666%);
  justify-content: space-between;
  vertical-align: middle;
}

.star {
  @apply bg-gray-30;
  position: relative;
  width: 100%;
  clip-path: polygon(97.5528% 34.5491%, 20.6107% 90.4509%, 50% 0%, 79.3893% 90.4509%, 2.44717% 34.5491%, 97.5528% 34.5491%, 20.6107% 90.4509%, 50% 0%, 79.3893% 90.4509%, 2.44717% 34.5491%);

  &::before {
    display: block;
    padding-top: 100%;
    content: '';
  }
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.button {
  position: relative;
  display: block;
  cursor: pointer;

  /* to make gaps part of a button */

  &::after {
    position: absolute;
    content: '';
    inset: -12.5%;
  }
}

.size-40 {
  width: 232rem;
}

.size-20 {
  width: 120rem;
}

.size-14 {
  width: 84rem;
}

.size-9 {
  width: 52rem;
}
