.titlePlacement {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -18rem;

  > span {
    position: relative;
    color: transparent;
    background-color: #fff;
    filter: blur(24px);
    user-select: none;
  }
}
