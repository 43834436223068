.text {

  p {
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }
}

.linkStyle {

  a {
    @apply text-gold-50 font-bold whitespace-nowrap underline;

    @media (hover: hover) {
      &:hover {
        @apply text-gold-30;
      }
    }
  }
}
