.header {
  min-height: var(--header-height);
}

.placeholder {
  padding-top: var(--header-height);
}

:global(.mobile) {
  --header-height: 48rem;

  :global(.fixed-header) {
    .header {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
    }
  }
}

:global(.desktop) {
  --header-height: 64rem;

  .header {
    @apply z-desktop-header; /* required for proper visibility when navigation dropdown is open */
  }
}
