.title {
  display: inline;

  &::after {
    position: absolute;
    z-index: -1;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.5;
    content: '';
    inset: -8rem;
  }
}

.title,
.established {
  position: relative;
  z-index: 2;
}

.logoWithMedias {
  margin-top: 90rem;
}

:global(.mobile) {

  .heroTextBlock {
    margin-top: -60rem;
    padding-top: 40rem;
  }

  .title {

    &::after {
      filter: blur(12rem);
    }
  }

  .logo {
    width: 272rem;
    margin-top: 24rem;
  }
}

:global(.desktop) {
  .heroTextBlock {
    margin-top: -24rem;
  }

  .title {

    &::after {
      filter: blur(24px);
    }
  }

  .description {
    padding-left: 112rem;
  }
}
