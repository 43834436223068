.root {
  border-bottom: 1px solid theme('colors.gold.50');
}

.icon {

  path:nth-child(2n) {
    transform-origin: center center;
    transition: transform 0.15s linear;
  }

  path:nth-child(2n+1) {
    transform-origin: center center;
    transition: transform 0.3s linear;
  }
}

.activeIcon {

  path:nth-child(2n) {
    transform: rotate(90deg);
    transition: transform 0.15s linear 0.15s;
  }

  path:nth-child(2n+1) {
    transform: rotate(180deg);
  }
}
